<template>
  <v-data-table
    :headers='headers'
    :items='items'
    item-key='Id'
    :rows-per-page-items='rows_per_page'
    :loading='loading'
    :pagination.sync='pagination'
    class='elevation-1'
  >
    <template slot='items' slot-scope='props'>
      <tr>
        <td>{{ props.item.Name }}</td>
        <td>{{ props.item.Description }}</td>
        <td style='text-align: center'>
          <button class='linkBtn' type='button' @click='viewDlg(props.item)'>
            View
          </button>
          <button
            class='linkBtn'
            type='button'
            v-show='allowEdit'
            @click='editDlg(props.item)'
          >
            Edit
          </button>
          <button
            class='linkBtn'
            type='button'
            v-show='allowDelete'
            @click='deleteDlg(props.item)'
          >
            Delete
          </button>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ['items', 'loading', 'allowEdit', 'allowDelete'],
  data() {
    return {
      rows_per_page: [25, 50],
      pagination: { sortBy: 'Id', descending: true },
      headers: [
        {
          text: 'Title',
          value: 'Name',
          sortable: true,
          width: '15%'
        },
        {
          text: 'Description',
          value: 'Description',
          sortable: true,
          width: '50%'
        },
        {
          text: 'Actions',
          value: '',
          sortable: false,
          width: '15%',
          align: 'center'
        }
      ]
    };
  },
  methods: {
    viewDlg(item) {
      this.$emit('view', item);
    },
    editDlg(item) {
      this.$emit('edit', item);
    },
    deleteDlg(item) {
      this.$emit('delete', item);
    }
  }
};
</script>

<style scoped></style>
