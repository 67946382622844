<template>
  <v-dialog v-model='dlgShow' scrollable persistent max-width='400px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          {{ dlgType === 0 ? 'Add' : dlgType === 1 ? 'Edit' : 'View' }} question
          group
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          :disabled='dlgType === 2'
          v-model='dlgModel.Name'
          label='Group name:'
        ></v-text-field>
        <v-textarea
          :disabled='dlgType === 2'
          v-model='dlgModel.Description'
          label='Description:'
          no-resize
          rows='2'
        ></v-textarea>
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-item>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
          v-show='dlgType !== 2'
          color='primary darken-1'
          flat
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../api/web';

export default {
  props: {
    dlgShow: { type: Boolean, default: false },
    dlgType: {
      type: Number,
      default: 0
    },
    obj: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    dlgShow: function() {
      if (this.obj != null && this.obj.Id) {
        this.dlgModel = {
          Name: this.obj.Name,
          Description: this.obj.Description
        };
      }
    }
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      dlgModel: {
        Name: '',
        Description: ''
      }
    };
  },
  methods: {
    closeDlg() {
      this.errors.clear();
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dlgModel = {
        Name: '',
        Description: ''
      };
      this.$emit('close');
    },
    async saveDlg() {
      if (
        this.dlgModel.Name === undefined ||
        this.dlgModel.Name.trim() === ''
      ) {
        await this.$notifyDlg.show('Invalid group name', 'Error', 'error');
        return;
      }
      if (this.dlgType === 0) {
        this.api_create(this.dlgModel);
      } else {
        this.dlgModel.Id = this.obj.Id;
        this.api_update(this.dlgModel);
      }
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/QuestionGroups', data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    },
    async api_update(data) {
      const res = await WebH.Put('/QuestionGroups/' + data.Id, data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('update', data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
