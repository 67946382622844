<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Question Groups
        </div>
      </v-flex>
      <v-flex sm12 xs12 lg12>
        <v-btn color='primary' v-show='allowAdd' @click='createDlg'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          New Question Group
        </v-btn>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-card>
          <v-card-text class='pa-0'>
            <QGDisplay
              :items='items'
              :loading='loading'
              :allow-edit='allowEdit'
              :allow-delete='allowAdd'
              @view='viewDlg'
              @edit='editDlg'
              @delete='deleteDlg'
            ></QGDisplay>
            <QGDlg
              :dlg-show='dlgShow'
              :dlg-type='dlgType'
              :obj='selectedItem'
              @close='closeDlg'
              @add='addNewItem'
              @update='updateItem'
            ></QGDlg>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import QGDlg from '../../components/question/QGDlg';
import QGDisplay from '../../components/question/QGDisplay';
import WebH from '../../api/web';

export default {
  components: {
    QGDlg,
    QGDisplay
  },
  created() {
    this.allowAdd = this.$store.getters['UserStore/permissions'].includes(
      'qs.a'
    );
    this.allowEdit = this.$store.getters['UserStore/permissions'].includes(
      'qs.a'
    );
    this.api_getAll();
  },
  data() {
    return {
      allowAdd: false,
      allowEdit: false,
      dlgShow: false,
      loading: false,
      items: [],
      dlgType: 0,
      selectedItem: null
    };
  },
  methods: {
    closeDlg() {
      this.dlgShow = false;
    },
    viewDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 2;
      this.dlgShow = true;
    },
    editDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 1;
      this.dlgShow = true;
    },
    createDlg() {
      this.selectedItem = null;
      this.dlgType = 0;
      this.dlgShow = true;
    },
    addNewItem(item) {
      this.items.push(item);
      this.$notify({
        type: 'info',
        text: 'Successfully added new question group'
      });
    },
    updateItem(item) {
      const index = this.items.findIndex(x => x.Id === item.Id);
      if (index > -1) {
        const tItem = this.items[index];
        tItem.Name = item.Name;
        tItem.Description = item.Description;
      }
    },
    async deleteDlg(item) {
      const res = await this.$notifyDlg.show(
        'Do you really want delete this question group?',
        'Delete question group',
        'warning',
        true
      );
      if (res) {
        this.api_delete(item);
      }
    },
    async api_getAll() {
      this.loading = true;
      this.items = [];
      let url = '/QuestionGroups/';
      const res = await WebH.Get(url, 'question groups');
      if (res.Success) {
        this.items = res.Data;
      }
      this.loading = false;
    },
    async api_delete(data) {
      const res = await WebH.Delete('/QuestionGroups/' + data.Id);
      if (res.Success) {
        const index = this.items.findIndex(x => x.Id === data.Id);
        if (index > -1) {
          this.items.splice(index, 1);
        }
        this.$notify({
          type: 'info',
          text: 'Successfully deleted the question group'
        });
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
    }
  }
};
</script>

<style scoped></style>
